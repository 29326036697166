@import './palette';

.dk-mat-container {
	box-shadow: 0 0 16px rgba(black, 0.1);
	background: white;
	padding: 40px;
}

.mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab {
	flex-grow: 0 !important;
}

// picker for most reports
.dk-picker {
	background: #cfcfcf;
	height: 88px;
	display: flex;
	align-items: center;
	padding: 0 20px;

	.mat-mdc-form-field-flex {
		height: 42px !important;
	}

	.mat-mdc-text-field-wrapper {
		border-radius: 0px !important;
	}

	.mat-mdc-form-field-infix {
		margin-top: -6px;
	}

	.today-btn {
		margin-left: 20px;
	}

	button[mat-button] {
		color: $ocean-blue !important;
	}

	mat-icon {
		background: #f5f5f5;
		height: 41px;
		width: 42px;
		display: flex;
		align-items: center;
		border-left: 1px solid #707070;
		justify-content: center;
		border-bottom: 1px solid #707070;
		cursor: pointer;
		margin-top: 0.1px;

		&:hover {
			border-bottom: 2px solid #000;
		}
	}

	.selected-week {
		position: absolute;
		display: block;
		width: 200px;
		top: 15px;
	}

	.export-label {
		margin-left: auto;
		color: $ocean-blue !important;
	}

	.mat-date-range-input-container {
		margin-top: -7px;
	}

	&.single-display {
		.mat-date-range-input-separator {
			display: none;
		}
	}
}

.mdc-tab-indicator {
	span {
		border-color: $emperor-gray !important;
		border-bottom: 1px solid $emperor-gray !important;
	}
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
	font-weight: bold !important;
	color: $emperor-gray !important;
}

mat-tab-header {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

dk-filter {
	label {
		color: $ocean-blue !important;
	}

	.mdc-line-ripple {
		opacity: 0;
	}
}

.dk-flex {
	display: flex;
}

.filter-header {
	mat-form-field {
		margin-top: 4px;
	}

	.mat-mdc-form-field-infix {
		display: flex !important;
	}

	.mat-mdc-select-value-text {
		font-size: 26px;
		position: relative;
		top: 8px;
		left: 23px;
	}
}
