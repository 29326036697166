@use '@angular/material' as mat;
@tailwind base;
@tailwind utilities;
//@import '@angular/material/core/theming/theming';

@import 'mixins';
@import 'extensions';
@import 'palette';
@import 'data-table';
@import 'brand/styles';
@import 'mat-dk';
@import 'vars';
@import './assets/custom-theme.scss';

@include mat.core();

$mat-indigo: $custom-primary;
$mat-pink: mat.$m2-pink-palette;
$mat-red: mat.$m2-red-palette;

$dash-king-ui-primary: mat.m2-define-palette($custom-primary);
$dash-king-ui-accent: mat.m2-define-palette($mat-pink, A200, A100, A400);
$dash-king-ui-warn: mat.m2-define-palette($mat-red);

$dash-typography: mat.m2-define-typography-config(
	$font-family: 'Roboto'
);

$dash-king-ui-theme: mat.m2-define-light-theme(
		(
			color: (
				primary: $dash-king-ui-primary,
				accent: $dash-king-ui-accent,
				warn: $dash-king-ui-warn
			),
			typography: $dash-typography
		)
);

@include mat.all-component-themes($dash-king-ui-theme);
// @include angular-material-theme($dash-king-ui-theme);

@font-face {
	font-family: BlockBerthold;
	src: url('/assets/fonts/block-berthold-regular.ttf') format('truetype');
}

:root {
	--main-bg-color: #00147e;
}

html,
body {
	position: relative;
	display: block;
	height: auto;
	width: 100vw;
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	overflow-y: auto;
}

body {
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.clickable {
	cursor: pointer;
}

.hidden {
	display: none;
}

.d-inline {
	display: inline !important;
}

.logo-app {
	background-image: url('/assets/logo/logo-dash.svg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.dk-error-dialog-container {
	width: 312px;
	height: 416px;
	background: #fbf5ee;
	border-radius: 6px;

	.mat-dialog-container {
		background: #fbf5ee !important;
	}
}

.w-100 {
	width: 100%;
}

.uppercase {
	text-transform: uppercase;
}

.mt-5 {
	margin-top: 2em;
}

.pl-0 {
	padding-left: 0 !important;
}

.font-weight-bold {
	font-weight: bold !important;
}

.font-weight-light {
	font-weight: 100;
}

.zoomed-out {
	zoom: 80%;
}

.export-label {
	border: 0 !important;
	color: #0077ba;
}

//.picker {
//	background: #e0e0e0;
//	justify-content: revert !important;
//}
//.picker > .mat-form-field > .mat-form-field-wrapper {
//	top: 10px;
//	margin-left: 14px;
//	.mat-form-field-flex {
//		background: white;
//	}
//}

// .cdk-overlay-container {
// 	/* Do you changes here */
// 	zoom: 80%;
// }

.picker > .today-btn {
	margin-left: 32px;
}

.picker > button {
	position: relative;
	top: 3px;
	color: #0077ba;
}

.mat-ink-bar {
	height: 3px !important;
	background-color: #505050 !important;
}

.mat-tab-label-active {
	opacity: revert !important;
	color: #505050;
	font-weight: bolder;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
	background: rgba(0, 186, 86, 0.2);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
	background-color: #00ba56;
}

.mat-expansion-panel-body {
	padding: 0 !important;
}

.mat-mdc-select-panel {
	min-width: 280px;
}

dk-page-header {
	#overview-picker {
		.mat-form-field-flex {
			padding-top: 0;
		}
	}

	.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
		position: relative;
		top: 5px;
	}
}

.text-right {
	text-align: right !important;
}

.text-left {
	text-align: left !important;
}

.row {
	display: flex;
}

dk-dtt-export .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: $cherry-red;
}

dk-dtt-export .mat-radio-button.mat-accent .mat-radio-inner-circle {
	background-color: $cherry-red;
}

dk-dtt-export .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
	background-color: $cherry-red;
}

dk-accounting {
	.mat-tab-body-wrapper {
		min-height: 80vh;
	}
}

.mat-select-panel {
	max-height: 384px !important;
}

div[class^='mat'],
div[class*=' mat'] {
	letter-spacing: 0 !important;
}

.mdc-radio__background {
	div {
		border-color: $cherry-red !important;
	}
}
