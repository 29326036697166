.logo {
	background-size: contain;
	max-width: 100%;
	height: 120px;
	background-repeat: no-repeat;
	background-image: url('/assets/logo/logo.svg');
}

.dk-hero-image {
	background-image: url('/assets/background/hero.jpg');
}

dk-page-aside-navigation {
	.logo {
		background-size: contain !important;
		height: 60px !important;
		width: 100% !important;
		margin: auto;
		background-repeat: no-repeat;
		background-position: center;
	}
}
