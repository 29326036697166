// White-Label
$white: #fff;
$black: #000;
$gray1: #a5a5a5;
$gray2: #ececec;
$gray3: #f7f7f7;
$emperor-gray: #505050;
$sand: #CFEEFF;
$sand2: #6078ee;
$light-grey: #f4f4f4;
$mid-grey: #505050;
$grey: #aaa;
$navy-blue: #0053b6;
$cherry-red: var(--main-bg-color);
$ocean-blue: #0077ba;
$light-blue: #d0edff;
$light-blue2: #eef9ff;
$light-blue3: #f7feff;
// Hotfix for unused FE-Component.
$pure-white: #ffffff;


